.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #99B898;
  height: 49px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 4rem;
}

.toolbar__logo {
  padding-left: 50px;
}

.toolbar__logo a {

  text-decoration: none;
  font-size: 1.3rem;
  margin-right: 50px;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
  
}

.toolbar_navigation-items a {

  text-decoration: none;
}


.main {
  margin-top: 56px;

}

@media (min-width: 768px) {
  .main {
    margin-left: 10%;
    margin-right: 10%;
  }
  .toolbar__logo {
    margin-left: 5rem;
  }

  .toolbar__navigation {
    padding: 0 6rem;
  }

}

@media (min-width: 425px) {
  .main {
    margin-top: 60px;
    margin-left: 10%;
    margin-right: 10%;
  }
  .toolbar__logo {
    margin-left: 1rem;
  }

.toolbar__navigation {
  padding: 0 0rem;
}

}

@media (min-width: 280px) {
  .main {
    margin-left: 5%;
    margin-right: 5%;
  }
  .toolbar__logo {
    margin-left: 0;
  }
  .toolbar__navigation {
    padding: 0 0rem;
  }
}

.body,html {
  height: 100%;
  padding-bottom: 65px;
  background-color: #3a3939;
  background-image: linear-gradient(to bottom, #99313f,#2A363B);

}

.bg {

  
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.social_links  {
  color: rgb(255, 255, 255);
  text-decoration: none;
  text-align: bottom;
  border: 1px outset red;
  padding: 5px;
  margin: 15px;
  
}

.links {
  margin-top: 25%;
  padding: 15px;
  border: 1px outset red;
  display: flex;
  flex-direction: row;
}

.active{
  color: #c52828;;
  font-weight:800;
}
.inactive{
  color: rgb(0, 0, 0);;
}


.text-field-small {
  text-decoration: none;
  padding-top: 15px;
  font-size:75%;
}
.text-field {
  color: rgb(255, 255, 255);
  background-color: #99b89879 ;
  padding: 15px;
  margin: 10px;
  border: 1px outset red;
}

/*
Use it in case little bit of debug needed
*{ border: 1px solid red; }

*/

.flex-box {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  flex-wrap: wrap;
  flex: 150px;
}